import * as amplitude from "@amplitude/analytics-browser";
import { init } from "@fullstory/browser";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Footer, NavBar } from "features/ui";
import AboutPage from "pages/about";
import Assessment from "pages/assessment";
import React, { useEffect } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import "./App.css";
import { GlobalStateProvider } from "./global-context";
import theme from "./theme";

const App = () => {
  init({
    orgId: "o-1CSBYD-na1",
    devMode: process.env.NODE_ENV !== "production",
  });

  let mode = process.env.REACT_APP_MODE || "web";

  // This amplitude Analytics Project API Key is not a secret (public),
  // for more details see: https://www.docs.developers.amplitude.com/guides/amplitude-keys-guide/#keys-overview
  amplitude.init("f82e4fbc1609faf112ff0cd05aa1c475", {
    defaultTracking: true,
  });

  useEffect(() => {
    if (
      window.location.hostname === "zerotrustassess.com" ||
      window.location.hostname === "www.zerotrustassess.com"
    ) {
      window.location.href =
        "https://www.beyondidentity.com/collections/zero-trust-assessment";
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStateProvider>
        <Router>
          {mode === "web" && (
            <>
              <NavBar />
              <div style={{ paddingTop: "76px" }} />{" "}
            </>
          )}
          <Routes>
            <Route path="/" element={<Assessment />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>

          {mode === "web" && <Footer />}
        </Router>
      </GlobalStateProvider>
    </ThemeProvider>
  );
};

export default App;
