import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import HubspotForm from "features/hubspot/hubspot-form";
import React from "react";
import useQueryParams from "utils/query-parser";
import { useGlobalState } from "../../../global-context";

const GetDemoModal = ({ open, handleClose }) => {
  const { sessionId } = useGlobalState();
  const { sid, utmSource } = useQueryParams();

  let portalId = process.env.REACT_APP_HUBSPOT_PORTAL_ID;
  let salesFormId = process.env.REACT_APP_HUBSPOT_SALES_FORM_ID;
  let sessId = sessionId;
  let source = "direct";
  if (sid) {
    sessId = sid;
  }
  if (utmSource) {
    source = utmSource;
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Get a demo</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <HubspotForm
            portalId={portalId}
            formId={salesFormId}
            sessionId={sessId}
            source={source}
            type={"sales"}
            targetId={"hubSpotFormTop"}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GetDemoModal;
