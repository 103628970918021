// GlobalContext.js
import React, { createContext, useContext, useState } from "react";

const GlobalContext = createContext();

export const useGlobalState = () => {
  return useContext(GlobalContext);
};

export const GlobalStateProvider = ({ children }) => {
  const [sessionId, setSessionId] = useState(generateRandomHex(12));

  return (
    <GlobalContext.Provider value={{ sessionId, setSessionId }}>
      {children}
    </GlobalContext.Provider>
  );
};

function generateRandomHex(length) {
  let result = "";
  const characters = "0123456789abcdef"; // Hexadecimal characters

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }

  return result;
}
