import { Box, Checkbox, FormControlLabel } from "@mui/material";
import Typography from "@mui/material/Typography";
import { IconTooltip } from "features/ui";
import React from "react";

const Factor = ({ formKey, label, tooltip, handleCheckboxChange }) => {
  return (
    <Box gap="1px" sx={{ display: "flex", alignItems: "center" }}>
      <FormControlLabel
        key={formKey}
        control={
          <Checkbox
            onChange={() => handleCheckboxChange(formKey)}
            name={formKey}
            color="primary"
            size="small"
            sx={{
              width: "38px",
              height: "28px",
            }}
          />
        }
        label={
          <Typography fontSize={14} fontWeight={400} lineHeight="150%">
            {label}
          </Typography>
        }
        sx={{
          marginRight: "4px"
        }}
      />
      <IconTooltip name={`${formKey}-tooltip`} tooltip={tooltip} />
    </Box>
  );
};

export default Factor;
