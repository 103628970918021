import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React from "react";

function LinkCard({ image, title, description, link }) {
  return (
    <Link
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      sx={{ textDecoration: "none" }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: "16px",
          height: "100%",
          boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.10)",
        }}
      >
        <img
          src={image}
          alt={title}
          style={{ width: "100%", objectFit: "cover" }}
        />
        <Link href={link} target="_blank" rel="noopener noreferrer">
          <Typography
            fontSize={20}
            fontWeight="700"
            lineHeight="140%"
            color="black"
            margin="8px 0 "
          >
            {title}
          </Typography>
        </Link>
        <Typography fontSize={16} fontWeight="400" lineHeight="160%">
          {description}
        </Typography>
      </Paper>
    </Link>
  );
}

export default LinkCard;
