import DevicesIcon from "@mui/icons-material/Devices";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { deviceFactorsMap } from "../../../factors";
import { useAssessmentContext } from "../assessment.context";
import { scoreCardContentMap } from "../results/score-card";
import BulletList from "./bullet-list";

const DeviceEnvironmentCard = () => {
  const { assessment } = useAssessmentContext();

  const [margin, setMargin] = useState("39px"); // Default margin

  useEffect(() => {
    // Define the function to adjust the margin based on screen width
    function adjustMargin() {
      const screenWidth = window.innerWidth;
      if (screenWidth < 900) {
        setMargin("0px"); // Adjusted margin for smaller screens
      } else {
        setMargin("39px"); // Default margin
      }
    }

    // Call the adjustMargin function initially
    adjustMargin();

    // Add an event listener to adjust margin when the window is resized
    window.addEventListener("resize", adjustMargin);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", adjustMargin);
    };
  }, []);

  let factorListItems = getDeviceListItems(assessment.deviceFactors);
  let { whyListItems, improveListItems } = getAnalysisListItems(
    assessment.deviceScore
  );

  let biListItems1 = [
    <Typography fontSize={16} fontWeight={400} lineHeight="160%">
      Beyond Identity will provide your organization optimal device assurance,
      as we conduct continuous posture checks on all physical and virtual
      assets. Our integration with EDRs, MDMs, ZTNAs, and other security
      products ensure orchestration and automation across all your security
      tools, enabling immediate action to restrict any device deemed out of
      compliance and facilitating real-time risk assessment of devices.
    </Typography>,
  ];

  let biListItems2 = [
    <Typography fontSize={16} fontWeight={400} lineHeight="160%">
      Evaluation of{" "}
      <Link
        href="https://www.beyondidentity.com/solutions/device-trust"
        target="_blank"
        rel="noopener noreferrer"
      >
        device compliance
      </Link>{" "}
      through policy-based authentication
    </Typography>,
    <Typography fontSize={16} fontWeight={400} lineHeight="160%">
      100+ risk signals, including firewall status, antivirus status, disk
      encryption status, and much more
    </Typography>,
    <Typography fontSize={16} fontWeight={400} lineHeight="160%">
      <Link
        href="https://www.beyondidentity.com/resource/zero-trust-and-continuous-authentication-a-partnership-for-network-security"
        target="_blank"
        rel="noopener noreferrer"
      >
        Continuous
      </Link>{" "}
      physical and virtual asset risk-signal analysis throughout a session
    </Typography>,
    <Typography fontSize={16} fontWeight={400} lineHeight="160%">
      Data ingestion from your existing security{" "}
      <Link
        href="https://www.beyondidentity.com/products/integrations"
        target="_blank"
        rel="noopener noreferrer"
      >
        integrations
      </Link>{" "}
      (EDR, MDM, ZTNA, etc.) to enrich policy decisions and enforce device
      controls
    </Typography>
  ];

  return (
    <>
      <Box display="flex" alignItems="center" gap="8px" marginBottom="24px">
        <DevicesIcon />
        <Typography
          variant="body2"
          fontSize={18}
          fontWeight={700}
          lineHeight="140%"
        >
          Your device environment
        </Typography>
      </Box>
      <Grid container spacing="78px">
        <Grid item xs={12} sm={12} md={6}>
          <Box>
            <Box
              display="flex"
              alignItems="center"
              gap="8px"
              marginBottom="24px"
              width="100%"
              minHeight="40px"
              padding="4px 8px"
              sx={{
                borderRadius: "8px",
                backgroundColor: `${
                  scoreCardContentMap.get(assessment.deviceScore).bgColor
                }`,
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body2"
                fontSize={16}
                fontWeight={700}
                lineHeight="150%"
                align="center"
              >
                Current device rating:{" "}
                {scoreCardContentMap.get(assessment.deviceScore).heading}
              </Typography>
            </Box>
            <Typography
              variant="body2"
              fontSize={16}
              fontWeight={700}
              lineHeight="160%"
            >
              Why you got the rating
            </Typography>
            <BulletList listItems={whyListItems} />
            {assessment.deviceScore < 4 && (
              <>
                <Typography
                  variant="body2"
                  fontSize={16}
                  fontWeight={700}
                  lineHeight="160%"
                >
                  How to improve your rating
                </Typography>
                <BulletList listItems={improveListItems} />
              </>
            )}
            <Typography
              variant="body2"
              fontSize={16}
              fontWeight={700}
              lineHeight="160%"
            >
              Analysis of device management practices
            </Typography>
            <BulletList listItems={factorListItems} />
          </Box>
          {margin === "0px" && <Divider />}
        </Grid>
        {margin === "39px" && (
          <Divider orientation="vertical" flexItem sx={{ mr: "-1px" }} />
        )}
        <Grid item xs={12} sm={12} md={6}>
          <Box>
            <Box
              display="flex"
              alignItems="center"
              gap="8px"
              marginBottom="24px"
              minHeight="40px"
              padding="4px 8px"
              sx={{
                borderRadius: "8px",
                backgroundColor: `${scoreCardContentMap.get(4).bgColor}`,
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body2"
                fontSize={16}
                fontWeight={700}
                lineHeight="150%"
                align="center"
              >
                Device rating with Beyond Identity:{" "}
                {scoreCardContentMap.get(4).heading}
              </Typography>
            </Box>
            <Typography
              variant="body2"
              fontSize={16}
              fontWeight={700}
              lineHeight="160%"
            >
              Why Beyond Identity is Optimal
            </Typography>
            <BulletList listItems={biListItems1} />
            <Typography
              variant="body2"
              fontSize={16}
              fontWeight={700}
              lineHeight="160%"
            >
              Beyond Identity's zero trust solution
            </Typography>
            <BulletList listItems={biListItems2} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

function getAnalysisListItems(deviceScore) {
  let whyListItems = [];
  switch (deviceScore) {
    case 1:
      whyListItems.push(
        <Typography fontSize={16} fontWeight={400} lineHeight="160%">
          Your rating is traditional because there is limited or no visibility
          into the devices accessing your resources and your authentication
          protocols do not consider device security posture.
        </Typography>
      );
      break;
    case 2:
      whyListItems.push(
        <Typography fontSize={16} fontWeight={400} lineHeight="160%">
          Your rating is initial because you have limited visibility into the
          devices accessing your resources and limited ability to enforce device
          security controls. Your device management practices may consist of
          traditional endpoint protection and device-based access controls that
          are primarily centered around perimeter-based security measures, which
          are not well suited to protecting cloud environments.You may also
          receive this score if you have MDM or EDR in place, but there are also
          BYOD / contractor devices in your environment. Those controls are
          typically not present on those devices, creating security gaps.
        </Typography>
      );
      break;
    case 3:
      whyListItems.push(
        <Typography fontSize={16} fontWeight={400} lineHeight="160%">
          Your rating is advanced because you have fuller visibility into the
          devices accessing your resources and the ability to establish whether
          security controls are present. However, you do not have the ability to
          automatically enforce those security controls in real-time, meaning
          insecure devices may still access your resources. Your visibility into
          device security posture may also be reliant on static or stale data
          either retrieved at initial login or checked after long intervals,
          leaving you susceptible to real-time changes in access risk.
        </Typography>
      );
      break;
    case 4:
      whyListItems.push(
        <Typography fontSize={16} fontWeight={400} lineHeight="160%">
          Your rating is optimal because you have full visibility into the
          devices accessing your resources and the ability to continuously
          enforce security controls on those devices. You establish device trust
          by continuously assessing device security posture, conducting
          real-time risk assessment, and including automated supply chain risk
          management with integrated threat protections.
        </Typography>
      );
      break;
    default:
    // do nothing
  }

  let improveListItems = [];
  if (deviceScore < 2) {
    improveListItems.push(
      <Typography fontSize={16} fontWeight={400} lineHeight="160%">
        <Typography
          variant="body2"
          style={{
            backgroundColor: scoreCardContentMap.get(2).bgColor,
            display: "inline",
            padding: "4px 8px",
            borderRadius: "8px",
          }}
          fontSize={16}
          fontWeight={700}
        >
          {scoreCardContentMap.get(2).heading}
        </Typography>{" "}
        : to get to an initial rating, start by establishing a basic device
        inventory and gaining visibility into your network and devices.
        Implement fundamental measures such as basic endpoint protection and
        device-based access controls.
      </Typography>
    );
  }
  if (deviceScore < 3) {
    improveListItems.push(
      <Typography fontSize={16} fontWeight={400} lineHeight="160%">
        <Typography
          variant="body2"
          style={{
            backgroundColor: scoreCardContentMap.get(3).bgColor,
            display: "inline",
            padding: "4px 8px",
            borderRadius: "8px",
          }}
          fontSize={16}
          fontWeight={700}
        >
          {scoreCardContentMap.get(3).heading}
        </Typography>{" "}
        : to get to an advanced rating, you should track your physical and
        virtual assets and enforce compliance. Advanced endpoint protection
        should be implemented, including security measures for mobile devices.
        However, if you are not able to automatically enforce security controls
        in real-time for all devices accessing your resources, there will still
        be security vulnerabilities.
      </Typography>
    );
  }
  if (deviceScore < 4) {
    improveListItems.push(
      <Typography fontSize={16} fontWeight={400} lineHeight="160%">
        <Typography
          variant="body2"
          style={{
            backgroundColor: scoreCardContentMap.get(4).bgColor,
            display: "inline",
            padding: "4px 8px",
            borderRadius: "8px",
          }}
          fontSize={16}
          fontWeight={700}
        >
          {scoreCardContentMap.get(4).heading}
        </Typography>{" "}
        : to get to an optimal rating, you should continuously track all your
        physical and virtual assets and enforce strict compliance. Orchestrate
        your security tools through automation while conducting real-time risk
        assessments on devices, providing the optimal level of zero trust.
      </Typography>
    );
  }

  return { whyListItems, improveListItems };
}

function getDeviceListItems(deviceFactors) {
  let labelItems = [];

  for (const [key, data] of deviceFactorsMap) {
    if (deviceFactors.includes(key)) {
      if (data.envText !== undefined && data.envText !== "") {
        labelItems.push(
          <Typography fontSize={16} fontWeight={400} lineHeight="160%">
            <strong>{data.label}</strong>: {data.envText}
          </Typography>
        );
      }
    }
  }

  return labelItems;
}

export default DeviceEnvironmentCard;
