import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import { deviceFactorsMap } from "../../../factors";
import Factor from "./factor";

function DeviceFactors({ setDeviceFactorsArray, error }) {
  return (
    <Box>
      <Typography fontSize={16} fontWeight={500} lineHeight="160%">
        Device: How do you manage your devices?
      </Typography>
      {error && (
        <Typography
          fontSize={12}
          fontWeight={400}
          lineHeight="160%"
          color="red"
        >
          Please make at least one selection.
        </Typography>
      )}
      {Array.from(deviceFactorsMap).map(([id, data]) => (
        <Factor
          key={id}
          formKey={id}
          label={data.label}
          tooltip={data.tooltip}
          handleCheckboxChange={setDeviceFactorsArray}
        />
      ))}
    </Box>
  );
}

export default DeviceFactors;
