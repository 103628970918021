import { Box, Button, Divider, Toolbar, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const handlePrivacyPolicy = () => {
    window.open("https://www.beyondidentity.com/privacy-policy", "_blank");
  };

  const handleWebsite = () => {
    window.open("https://beyondidentity.com", "_blank");
  };

  const handleContactUs = () => {
    const mailtoLink = `mailto:zerotrustassessment@beyondidentity.com?subject=Inquiry%20about%20The%20Zero%20Trust%20Assessment`;
    window.location.href = mailtoLink;
  };

  const handleLeaveFeedback = () => {
    window.open("https://8uy05ohlyqq.typeform.com/to/SlXlRVIJ", "_blank");
  };

  const [v, setV] = useState(false); // Default margin
  const [margin, setMargin] = useState("120px"); // Default margin

  useEffect(() => {
    // Define the function to adjust the margin based on screen width
    function adjustMargin() {
      const screenWidth = window.innerWidth;
      if (screenWidth < 850) {
        setV(false); // Adjusted margin for smaller screens
      } else {
        setV(true); // Default margin
      }
      if (screenWidth < 1200) {
        setMargin("8px"); // Adjusted margin for smaller screens
      } else {
        setMargin("120px"); // Default margin
      }
    }

    // Call the adjustMargin function initially
    adjustMargin();

    // Add an event listener to adjust margin when the window is resized
    window.addEventListener("resize", adjustMargin);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", adjustMargin);
    };
  }, []);

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "#fff", // Make the Navbar transparent
        boxShadow: "none", // Remove the shadow
        padding: `0px ${margin}`,
      }}
    >
      {v ? (
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box gap="32px" sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="body2"
              fontSize={14}
              fontWeight={500}
              sx={{ color: "#767676" }}
            >
              &copy; {new Date().getFullYear()} Beyond Identity
            </Typography>
            <Divider orientation="vertical" flexItem></Divider>
            <Button component={Link} to="/about" color="primary">
              <Typography
                variant="body2"
                fontSize={14}
                fontWeight={700}
                sx={{ color: "#06042F" }}
              >
                What is Zero Trust?
              </Typography>
            </Button>
            <Button color="primary" onClick={handleWebsite}>
              <Typography
                variant="body2"
                fontSize={14}
                fontWeight={700}
                sx={{ color: "#06042F" }}
              >
                Beyond Identity Home
              </Typography>
            </Button>
            <Button color="primary" onClick={handleContactUs}>
              <Typography
                variant="body2"
                fontSize={14}
                fontWeight={700}
                sx={{ color: "#06042F" }}
              >
                Contact us
              </Typography>
            </Button>
            <Button color="primary" onClick={handleLeaveFeedback}>
              <Typography
                variant="body2"
                fontSize={14}
                fontWeight={700}
                sx={{ color: "#06042F" }}
              >
                Leave Feedback
              </Typography>
            </Button>
          </Box>
          <Box>
            <Button color="primary" onClick={handlePrivacyPolicy}>
              <Typography
                variant="body2"
                fontSize={14}
                fontWeight={600}
                sx={{ color: "#767676" }}
              >
                Privacy Policy
              </Typography>
            </Button>
          </Box>
        </Toolbar>
      ) : (
        <Toolbar
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "12px",
          }}
        >
          <Box gap="32px">
            <Typography
              variant="body2"
              fontSize={14}
              fontWeight={500}
              sx={{ color: "#767676", padding: "6px 8px" }}
            >
              &copy; {new Date().getFullYear()} Beyond Identity
            </Typography>
            <Divider orientation="vertical" flexItem></Divider>
            <Button component={Link} to="/about" color="primary">
              <Typography
                variant="body2"
                fontSize={14}
                fontWeight={700}
                sx={{ color: "#06042F" }}
              >
                What is Zero Trust?
              </Typography>
            </Button>
            <Button color="primary" onClick={handleWebsite}>
              <Typography
                variant="body2"
                fontSize={14}
                fontWeight={700}
                sx={{ color: "#06042F" }}
              >
                Beyond Identity Home
              </Typography>
            </Button>

            <Button color="primary" onClick={handleContactUs}>
              <Typography
                fontSize={14}
                fontWeight={700}
                sx={{ color: "#06042F" }}
              >
                Contact us
              </Typography>
            </Button>
            <Button color="primary" onClick={handleLeaveFeedback}>
              <Typography
                variant="body2"
                fontSize={14}
                fontWeight={700}
                sx={{ color: "#06042F" }}
              >
                Leave Feedback
              </Typography>
            </Button>
            <Button color="primary" onClick={handlePrivacyPolicy}>
              <Typography
                variant="body2"
                fontSize={14}
                fontWeight={600}
                sx={{ color: "#767676" }}
              >
                Privacy Policy
              </Typography>
            </Button>
          </Box>
        </Toolbar>
      )}
    </AppBar>
  );
};

export default Footer;
