import { Box, Container } from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useAssessmentContext } from "../assessment.context";
import CostSavingsCard from "./cost-savings-card";
import WistiaPlayer from "./demo/wistia-player";
import DeviceEnvironmentCard from "./device-environment-card";
import EmailSignup from "./email-signup";
import IdentityEnvironmentCard from "./identity-environment-card";

const MoreInfo = () => {
  let mode = process.env.REACT_APP_MODE || "web";

  const { assessment } = useAssessmentContext();

  const [margin, setMargin] = useState("96px"); // Default margin
  const [margin2, setMargin2] = useState("192px"); // Default margin
  const [imgPct, setImgPct] = useState("50%"); // Default margin

  useEffect(() => {
    // Define the function to adjust the margin based on screen width
    function adjustMargin() {
      const screenWidth = window.innerWidth;
      if (screenWidth < 1200) {
        setMargin("24px"); // Adjusted margin for smaller screens
        setMargin2("24px"); // Adjusted margin for smaller screens
        setImgPct("75%"); // Adjusted margin for smaller screens
      } else {
        setMargin("96px"); // Default margin
        setMargin2("144px"); // Default margin
        setImgPct("50%"); // Default
      }
    }

    // Call the adjustMargin function initially
    adjustMargin();

    // Add an event listener to adjust margin when the window is resized
    window.addEventListener("resize", adjustMargin);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", adjustMargin);
    };
  }, []);

  return assessment.identityScore > 0 ? (
    <>
      <Container
        maxWidth="false"
        sx={{ backgroundColor: "#fff", margin: 0 }}
        disableGutters
      >
        <Box padding={`0px ${margin} 32px`}>
          <Typography
            variant="body2"
            align="center"
            fontWeight={700}
            fontSize={32}
          >
            Deep dive into your zero trust environment
          </Typography>
        </Box>
        <Box
          padding={`0px ${margin} 32px`}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <img
            src="images/cisa_mtn.jpg"
            alt="Beyond Identity"
            style={{ width: `${imgPct}`, objectFit: "cover" }}
          />
        </Box>
        <Box
          padding={`0px ${margin} 32px`}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            fontSize={14}
            fontWeight={400}
            lineHeight="160%"
            align="center"
            sx={{ fontStyle: "italic", maxWidth: 725 }}
          >
            Each subsequent zero trust stage requires greater levels of detail
            and complexity for adoption, but also exponentially more protection.
            Source: CISA, Zero Trust Maturity Model 2.0 - Figure 2: Zero Trust
            Maturity Journey
          </Typography>
        </Box>
      </Container>

      <Container
        maxWidth="false"
        sx={{ backgroundColor: "#FBFBFB", margin: 0 }}
        disableGutters
      >
        <Box sx={{ width: "100%", padding: `32px ${margin} 64px` }}>
          <IdentityEnvironmentCard />
        </Box>
      </Container>

      <Container
        maxWidth="false"
        sx={{ backgroundColor: "#FBFBFB", margin: 0 }}
        disableGutters
      >
        <Box sx={{ width: "100%", padding: `32px ${margin} 64px ${margin}` }}>
          <DeviceEnvironmentCard />
        </Box>
      </Container>

      <div id="demo"></div>
      <Container
        maxWidth="false"
        sx={{ backgroundColor: "#fff", margin: 0 }}
        disableGutters
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ width: "100%", padding: `64px ${margin2} 64px ${margin2}` }}
        >
          <Box sx={{ width: "75%" }}>
            <Typography
              variant="body2"
              align="center"
              fontWeight={700}
              fontSize={32}
              marginBottom="32px"
            >
              Explore the Beyond Identity Platform
            </Typography>
            <WistiaPlayer
              videoId="i0f8r3cljn"
              wrapper="wistia-player-container-1"
            />
          </Box>
        </Box>
      </Container>

      <Container
        maxWidth="false"
        sx={{ backgroundColor: "#F6F7FC", margin: 0 }}
        disableGutters
      >
        <Box sx={{ width: "100%", padding: `64px ${margin2} 64px ${margin2}` }}>
          <CostSavingsCard />
        </Box>
      </Container>

      {mode === "web" && (
        <Container
          maxWidth="false"
          sx={{ backgroundColor: "#2C355B", margin: 0 }}
          disableGutters
        >
          <Box sx={{ width: "100%", padding: `96px ${margin} 96px ${margin}` }}>
            <EmailSignup />
          </Box>
        </Container>
      )}
    </>
  ) : (
    <Container maxWidth="false"></Container>
  );
};

export default MoreInfo;
