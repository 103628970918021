import { useLocation } from "react-router-dom";

const useQueryParams = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Get the values for "sid" and "utm_source" query parameters
  const sid = queryParams.get("sid");
  const utmSource = queryParams.get("utm_source");

  return { sid, utmSource };
}

export default useQueryParams;
