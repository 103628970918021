import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { useAssessmentContext } from "../assessment.context";
import BulletList from "../results/bullet-list";

const CostSavingsCard = () => {
  const { assessment } = useAssessmentContext();

  const [margin, setMargin] = useState("39px"); // Default margin

  useEffect(() => {
    // Define the function to adjust the margin based on screen width
    function adjustMargin() {
      const screenWidth = window.innerWidth;
      if (screenWidth < 900) {
        setMargin("0px"); // Adjusted margin for smaller screens
      } else {
        setMargin("39px"); // Default margin
      }
    }

    // Call the adjustMargin function initially
    adjustMargin();

    // Add an event listener to adjust margin when the window is resized
    window.addEventListener("resize", adjustMargin);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", adjustMargin);
    };
  }, []);

  const [numUsers, setNumUsers] = useState(100);

  const pdfURL = "/pdfs/BeyondIdentityPricingJustification.pdf";

  const handleInputChange = (e) => {
    const value = e.target.value;

    // Use parseInt to get the numeric value and ensure it's between 0 and 99999
    let numericValue = parseInt(value, 10);

    // Check for empty values
    if (isNaN(numericValue)) {
      numericValue = 0;
    }

    if (numericValue >= 0 && numericValue <= 999999) {
      setNumUsers(numericValue);
    }
  };

  let { savings, reasons } = calculateSavings(numUsers, assessment);

  return (
    <>
      <Typography
        variant="body2"
        align="center"
        fontWeight={700}
        fontSize={32}
        marginBottom="32px"
      >
        Cost savings with Beyond Identity
      </Typography>
      <Card
        sx={{
          boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.10)",
          height: "100%",
          width: "100%",
          outline: "none",
          padding: "0px 0px",
        }}
      >
        <Grid container spacing={2} sx={{ padding: 0 }}>
          <Grid item xs={12} sm={12} md={4} sx={{ padding: 0 }}>
            <Box
              padding="32px"
              backgroundColor="#FBFBFB"
              sx={{
                height: "100%",
                width: "100%",
              }}
            >
              <Box marginRight="39px">
                <Typography
                  variant="body2"
                  fontSize={18}
                  fontWeight={700}
                  lineHeight="140%"
                >
                  How many users do you have?
                </Typography>
                <TextField
                  value={numUsers}
                  sx={{ marginTop: "16px", width: "100%" }}
                  variant="outlined"
                  type="text"
                  onInput={handleInputChange}
                  placeholder="Number of users"
                />
              </Box>
            </Box>
            {margin === "0px" && <Divider />}
          </Grid>
          {margin === "39px" && (
            <Divider orientation="vertical" flexItem sx={{ mr: "-1px" }} />
          )}
          <Grid item xs={12} sm={12} md={4} sx={{ padding: 0 }}>
            <Box padding="32px">
              <Typography
                variant="body2"
                fontSize={18}
                fontWeight={700}
                lineHeight="140%"
                marginBottom="16px"
              >
                Your potential annual savings
              </Typography>
              <Typography fontSize={56} fontWeight={700} lineHeight="160%">
                ${" "}
                <span style={{ color: "#3727D6" }}>
                  <CountUp end={savings} duration={2} preserveValue />
                </span>
              </Typography>
              <Typography fontSize={24} fontWeight={400} lineHeight="160%">
                and up to{" "}
                <strong style={{ color: "#000" }}>
                  <i>millions</i>
                </strong>{" "}
                in a breach
              </Typography>
            </Box>
            {margin === "0px" && <Divider />}
          </Grid>
          {margin === "39px" && (
            <Divider orientation="vertical" flexItem sx={{ mr: "-1px" }} />
          )}
          <Grid item xs={12} sm={12} md={4} sx={{ padding: 0 }}>
            <Box padding="32px">
              <Typography
                variant="body2"
                fontSize={18}
                fontWeight={700}
                lineHeight="140%"
              >
                Breakdown
              </Typography>
              <BulletList listItems={reasons} />
              <Typography
                fontSize={14}
                fontWeight={400}
                lineHeight="160%"
                sx={{ fontStyle: "italic" }}
              >
                *Estimated using{" "}
                <Link href={pdfURL} target="_blank" rel="noopener noreferrer">
                  Beyond Identity's Pricing Justification
                </Link>{" "}
                fact sheet
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

const calculateSavings = (numUsers, assessment) => {
  let savings = 0;
  let reasons = [];

  reasons.push(
    <>
      <Typography variant="body2" fontSize={22} fontWeight={700} lineHeight="160%">
        Cyber Insurance Assistance
      </Typography>
      <Typography fontSize={16} fontWeight={400} lineHeight="160%">
        We mitigate cyber risk, enabling organizations to obtain or expand
        coverage and potentially reduce premiums.
      </Typography>
    </>
  );

  // identity factors - password
  for (const item of assessment.identityFactors) {
    if (item === "password") {
      savings += numUsers * 4 * 12; // save $4/seat/month password manager and $200k/annual PAM ($48 annual)
      savings += numUsers * 30; // average cost of $30 per user, 1 password a year reset, productitivity ($30 annual)
      reasons.push(
        <NumberTextDisplay
          number={numUsers * 4 * 12}
          text={
            "of potential savings by reducing the number of password manager licenses ($4/seat/month)"
          }
        />
      );
      reasons.push(
        <NumberTextDisplay
          number={numUsers * 30}
          text={`of potential savings by eliminating password-related support costs. It is estimated at $30/user/year in help desk calls and workplace
            productivity`}
        />
      );
    }
  }

  // identity factors - MFA
  for (const item of assessment.identityFactors) {
    const mfaArray = [
      "sms",
      "email",
      "authenticator-app",
      "biometric",
      "hardware-key",
      "push",
    ];
    if (mfaArray.includes(item)) {
      let saved = numUsers * 3 * 12;
      savings += saved; // save $3 per user per month on MFA ($36 annual)
      reasons.push(
        <NumberTextDisplay
          number={saved}
          text={`of potential savings by reducing the number of your MFA licenses and replacing with Beyond Identity ($3/seat/month)`}
        />
      );
      break;
    }
  }

  // identity factors - MFA
  for (const item of assessment.identityFactors) {
    const mfaArray = ["hardware-key"];
    if (mfaArray.includes(item)) {
      savings += numUsers * 25; // save $25 per user per month on yubikeys ($25 annual)
      reasons.push(
        <NumberTextDisplay
          number={numUsers * 25}
          text={`of potential savings by reducing the number of hardware security keys ($25/key/user)`}
        />
      );
      break;
    }
  }

  // device factors
  for (const item of assessment.deviceFactors) {
    if (item === "vpn") {
      let saved = numUsers * 8 * 12; // Current spend of $8 per month per user.
      savings += saved;
      reasons.push(
        <NumberTextDisplay
          number={saved}
          text={`of potential savings by reducing the number of VPN licenses ($8/seat/month)`}
        />
      );
    }
    if (item === "mdm") {
      let saved = numUsers * 4 * 12;
      savings += saved; // Current spend of $4/device/month
      reasons.push();
      reasons.push(
        <NumberTextDisplay
          number={saved}
          text={`of potential savings by reducing MDM licenses for BYOD/Contractor devices ($4/seat/month)`}
        />
      );
    }
  }

  return {
    savings: savings,
    reasons: reasons,
  };
};

const NumberTextDisplay = ({ number, text }) => (
  <>
    <Typography
      variant="body2"
      fontSize={22}
      fontWeight={700}
      lineHeight="160%"
    >
      $ <CountUp end={number} duration={2} preserveValue />
    </Typography>
    <Typography fontSize={16} fontWeight={400} lineHeight="160%">
      {text}
    </Typography>
  </>
);

export default CostSavingsCard;
