import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import { loginFactorsMap } from "../../../factors";
import Factor from "./factor";

function IdentityFactors({ setIdentityFactorsArray, error }) {
  return (
    <Box>
      <Typography fontSize={16} fontWeight={500} lineHeight="160%">
        Identity: How are your users authenticating?
      </Typography>
      {error && (
        <Typography fontSize={12} fontWeight={400} lineHeight="160%" color="red">
          Please make at least one selection.
        </Typography>
      )}
      {Array.from(loginFactorsMap).map(([id, data]) => (
        <Factor
          key={id}
          formKey={id}
          label={data.label}
          tooltip={data.tooltip}
          handleCheckboxChange={setIdentityFactorsArray}
        />
      ))}
    </Box>
  );
}

export default IdentityFactors;
