import { Box, Card, Link } from "@mui/material";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import {
  AssessmentChart,
  AssessmentContextProvider,
  FactorsCard,
  MoreInfo,
} from "features/assessment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link as RouterLink } from "react-router-dom";

const Assessment = () => {
  const [margin, setMargin] = useState("96px"); // Default margin

  let mode = process.env.REACT_APP_MODE || "web";

  useEffect(() => {
    // Define the function to adjust the margin based on screen width
    function adjustMargin() {
      const screenWidth = window.innerWidth;
      if (screenWidth < 1200) {
        setMargin("24px"); // Adjusted margin for smaller screens
      } else {
        setMargin("96px"); // Default margin
      }
    }

    // Call the adjustMargin function initially
    adjustMargin();

    // Add an event listener to adjust margin when the window is resized
    window.addEventListener("resize", adjustMargin);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", adjustMargin);
    };
  }, []);

  useEffect(() => {
    // Scroll to the top of the page when the component is mounted (i.e., when a link is clicked).
    window.scrollTo(0, 0);
  }, []);

  return (
    <AssessmentContextProvider>
      <Helmet>
        <title>Zero Trust Assessment</title>
        <meta
          name="Zero Trust Assessment"
          content="Zero Trust Assessment, powered by Beyond Identity."
        />
      </Helmet>
      {mode === "web" && (
        <Container
          maxWidth="false"
          sx={{
            backgroundImage: "url(images/background.png)",
            backgroundSize: "cover", // Optional: Adjust the background size
            // backgroundRepeat: "no-repeat", // Optional: Adjust the background repeat
            backgroundPosition: "center center", // Center the image both horizontally and vertically
          }}
        >
          <Box padding="32px 0 48px 0">
            <Typography
              variant="body2"
              align="center"
              fontSize={20}
              fontWeight="700"
              lineHeight="120%"
              sx={{ marginBottom: "8px" }}
            >
              Identity and Device
            </Typography>
            <Typography
              variant="body2"
              align="center"
              fontSize={48}
              fontWeight="700"
              lineHeight="120%"
            >
              Zero Trust Assessment
            </Typography>
            <Typography
              fontSize={16}
              fontWeight="400"
              lineHeight="160%"
              sx={{
                maxWidth: 725, // Adjust the maximum width as needed
                margin: "8px auto 0px auto",
              }}
            >
              <RouterLink to="/about">Zero trust</RouterLink> is a cybersecurity
              paradigm that shifts security away from traditional network-based
              perimeters and instead focuses on the direct protection of
              resources through continuous analysis of users and their devices.
            </Typography>
            <Typography
              fontSize={16}
              fontWeight="400"
              lineHeight="160%"
              sx={{
                maxWidth: 725, // Adjust the maximum width as needed
                margin: "16px auto 0px auto",
              }}
            >
              Referencing the{" "}
              <Link
                href="https://www.cisa.gov/zero-trust-maturity-model"
                target="_blank"
                rel="noopener noreferrer"
              >
                CISA Zero Trust Maturity Model
              </Link>
              , we designed this tool to provide insights into your
              organization's current position within a zero trust framework, and
              guide you toward achieving an optimal zero trust implementation.
            </Typography>
          </Box>
        </Container>
      )}
      <Container
        maxWidth="false"
        sx={{
          backgroundColor: "#ffffff",
          padding: `60px ${margin} 64px ${margin}`,
        }}
        disableGutters
      >
        <Card
          sx={{
            boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.10);", // Adjust the values as needed
          }}
        >
          <Grid container spacing="0px" sx={{ display: "flex", margin: `0` }}>
            <Grid item xs={12} sm={12} md={4}>
              <FactorsCard />
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              <AssessmentChart />
            </Grid>
          </Grid>
        </Card>
      </Container>

      <MoreInfo />
    </AssessmentContextProvider>
  );
};

export default Assessment;
