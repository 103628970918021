import { Box, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import HubspotForm from "features/hubspot/hubspot-form";
import React from "react";
import useQueryParams from "utils/query-parser";
import { useGlobalState } from "../../../global-context";

function EmailSignup() {
  const { sessionId } = useGlobalState();
  let abTest = parseInt(sessionId, 16) % 2;
  const { sid, utmSource } = useQueryParams();

  // If it is part of an email campaign, default to sales for AB Test.
  if (utmSource === "email") {
    abTest = 0;
  }

  let hubspotInputs = {
    portalId: process.env.REACT_APP_HUBSPOT_PORTAL_ID,
    salesFormId: process.env.REACT_APP_HUBSPOT_SALES_FORM_ID,
    nurtureFormId: process.env.REACT_APP_HUBSPOT_NURTURE_FORM_ID,
  };

  // 0
  let sales = {
    header: "Discover how we can secure your environment",
    portalId: hubspotInputs.portalId,
    formId: hubspotInputs.salesFormId,
  };
  // 1
  let marketing = {
    header: "Stay up to date on the latest zero trust news",
    portalId: hubspotInputs.portalId,
    formId: hubspotInputs.nurtureFormId,
  };

  // Vars for submission
  let inputs = abTest === 0 ? sales : marketing;
  let sessId = sessionId;
  let source = "direct";
  if (sid) {
    sessId = sid;
  }
  if (utmSource) {
    source = utmSource;
  }
  let type = abTest === 0 ? "sales" : "marketing";

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Typography
        variant="body2"
        align="center"
        fontWeight={700}
        fontSize={40}
        color="#fff"
        sx={{
          maxWidth: 725, // Adjust the maximum width as needed
          margin: "0px auto 32px auto",
        }}
      >
        {inputs.header}
      </Typography>
      <Paper
        elevation={0}
        sx={{
          backgroundColor: "#FBFBFB",
          padding: "1px",
          boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.10)",
        }}
      >
        <HubspotForm
          portalId={inputs.portalId}
          formId={inputs.formId}
          sessionId={sessId}
          source={source}
          type={type}
          targetId={"hubSpotFormBottom"}
        />
      </Paper>
    </Box>
  );
}

export default EmailSignup;
