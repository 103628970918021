import { Link, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import LinkCard from "../features/about/link-card/link-card";

const AboutPage = () => {
  let articles = [
    {
      title: "CISA Zero Trust Maturity Model",
      description: `This model was developed by the Cybersecurity and Infrastructure Security Agency to help assess and advance an organization's adoption of zero trust principles.`,
      image: "images/cisa.png",
      link: "https://www.cisa.gov/zero-trust-maturity-model",
    },
    {
      title: "NIST Zero Trust Architecture",
      description:
        "This architecture was developed by the National Institute of Standards and technology and advocates for continuous verification of identity and strict access controls.",
      image: "images/nist.png",
      link: "https://www.nist.gov/publications/zero-trust-architecture/",
    },
    {
      title: "CIS Critical Security Controls Version 8",
      description:
        "The Center for Internet Security created a list of cybersecurity best practices to protect organizations from various cyber threats.",
      image: "images/cis.png",
      link: "https://www.cisecurity.org/controls/v8",
    },
    {
      title: "Cloud Security Alliance's Cloud Controls Matrix (CCM)",
      description:
        "CCM is a comprehensive framework that provides best practices for mitigating security risks in cloud environments.",
      image: "images/csa.png",
      link: "https://cloudsecurityalliance.org/research/cloud-controls-matrix/",
    },
    {
      title: "Zero Trust Authentication and Identity and Access Management",
      description:
        "Learn more about how ZTA and IAM work together to enhance cybersecurity.",
      image: "images/zta_iam.png",
      link: "https://www.beyondidentity.com/resources/zero-trust-authentication-and-identity-and-access-management",
    },
    {
      title: "Zero Trust Masterclass",
      description:
        "Check out Beyond Identity's Zero Trust Masterclass, an in-depth series on the principles and best practices of Zero Trust security.",
      image: "images/masterclass.png",
      link: "https://www.beyondidentity.com/resources?f%5B0%5D=topics%3A96",
    },
  ];

  const [margin, setMargin] = useState("96px"); // Default margin
  const [margin2, setMargin2] = useState("288px"); // Default margin

  useEffect(() => {
    // Define the function to adjust the margin based on screen width
    function adjustMargin() {
      const screenWidth = window.innerWidth;
      if (screenWidth < 900) {
        setMargin("16px"); // Adjusted margin for smaller screens
        setMargin2("16px"); // Adjusted margin for smaller screens
      } else if (screenWidth < 1400) {
        setMargin2("120px"); // Adjusted margin for smaller screens
      } else {
        setMargin("96px"); // Default margin
        setMargin2("288px"); // Default margin
      }
    }

    // Call the adjustMargin function initially
    adjustMargin();

    // Add an event listener to adjust margin when the window is resized
    window.addEventListener("resize", adjustMargin);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", adjustMargin);
    };
  }, []);

  useEffect(() => {
    // Scroll to the top of the page when the component is mounted (i.e., when a link is clicked).
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Container
        maxWidth="false"
        disableGutters
        sx={{
          margin: 0,
          padding: `80px ${margin}`,
          backgroundImage: "url(images/background.png)",
          backgroundSize: "cover", // Optional: Adjust the background size
          // backgroundRepeat: "no-repeat", // Optional: Adjust the background repeat
          backgroundPosition: "center bottom", // Center the image both horizontally and vertically
        }}
      >
        <Typography
          fontSize={48}
          fontWeight="700"
          lineHeight="140%"
          align="center"
        >
          What is Zero Trust?
        </Typography>
      </Container>
      <Container
        maxWidth="false"
        disableGutters
        sx={{ padding: `64px ${margin}`, backgroundColor: "#fff" }}
      >
        <Typography
          fontSize={16}
          fontWeight="400"
          lineHeight="160%"
          sx={{
            maxWidth: 668, // Adjust the maximum width as needed
            margin: "0px auto",
          }}
        >
          Zero trust is a cybersecurity paradigm that shifts security away from
          traditional network-based perimeters and instead focuses on the direct
          protection of resources through continuous analysis of users and
          their devices.
          <br /> <br />
          In a zero trust architecture, trust is not assumed based on network
          location (local networks vs. internet) or device ownership (enterprise
          or personal). Authentication and authorization are required for each
          session, with the emphasis on protecting resources rather than network
          segments. Zero trust addresses the challenges posed by remote users,
          bring your own device (BYOD), and cloud-based assets, ensuring a more
          robust security posture.
          <br /> <br />
          Our tool aligns with and references the{" "}
          <Link
            href="https://www.cisa.gov/zero-trust-maturity-model"
            target="_blank"
            rel="noopener noreferrer"
          >
            CISA (Cybersecurity and Infrastructure Security Agency) Zero Trust
            Maturity Model
          </Link>{" "}
          for its rating system. This is one of the models that provide a
          structured framework to assess an organization's adoption of Zero
          Trust. When evaluating your identity and device rating in our
          assessment, we are evaluating in the context of the the CISA model.
        </Typography>
      </Container>
      <Container
        maxWidth="false"
        disableGutters
        sx={{ padding: `0px ${margin2} 64px `, backgroundColor: "#fff" }}
      >
        <Typography
          fontSize={32}
          fontWeight="700"
          lineHeight="140%"
          align="center"
          marginBottom="32px"
        >
          Additional resources
        </Typography>
        <Grid container spacing="24px">
          {articles.map((item, index) => (
            <Grid item xs={12} sm={6} md={6} lg={6} xl={4} key={index}>
              <LinkCard
                title={item.title}
                description={item.description}
                image={item.image}
                link={item.link}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};

export default AboutPage;
