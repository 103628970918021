import { Box } from "@mui/material";
import React, { Component } from "react";
import "./hubspot.css";
import * as amplitude from '@amplitude/analytics-browser';

class HubspotForm extends Component {
  onFormSubmit = ($form) => {
    let email = $form.find("input[name='email']").val();
    const { sessionId, source, type } = this.props; // Access data via props

    let postData = {
      sid: sessionId,
      source: source,
      type: type,
      email: email,
    };

    const identifyEvent = new amplitude.Identify();
    identifyEvent.set('email', email);
    amplitude.identify(identifyEvent);

    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((response) => {
        if (!response.ok) {
          console.error("POST request failed.");
        }
      })
      .catch((error) => {
        console.error("POST request failed.");
      });
  };

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          target: `#${this.props.targetId}`,
          region: "na1",
          portalId: `${this.props.portalId}`,
          formId: `${this.props.formId}`,
          onFormSubmit: this.onFormSubmit,
        });
      }
    });
  }

  render() {
    return (
      <Box padding="12px" backgroundColor="#FBFBFB">
        <Box id={this.props.targetId} />
      </Box>
    );
  }
}

export default HubspotForm;
