import { List, ListItem } from "@mui/material";

function BulletList({ listItems }) {
  return (
    <List sx={{ p: 0, margin: "16px 0px" }}>
      {listItems.map((item, index) => (
        <ListItem
          sx={{ display: "list-item", padding: 0, marginBottom: "16px" }}
          key={index}
        >
          {item}
        </ListItem>
      ))}
    </List>
  );
}

export default BulletList;
