import React, { createContext, useContext, useState } from "react";

const AssessmentContext = createContext();

export function AssessmentContextProvider({ children }) {
  const [assessment, setAssessment] = useState({
    loading: false,
    biFactor: false,
    identityFactors: [],
    identityScore: 0,
    deviceFactors: [],
    deviceScore: 0,
  });

  return (
    <AssessmentContext.Provider value={{ assessment, setAssessment }}>
      {children}
    </AssessmentContext.Provider>
  );
}

export function useAssessmentContext() {
  return useContext(AssessmentContext);
}
