import { Button, Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import useQueryParams from "utils/query-parser";
import { useGlobalState } from "../../../global-context";
import { useAssessmentContext } from "../assessment.context";
import DeviceFactors from "./device-factors";
import IdentityFactors from "./identity-factors";

function FactorsCard() {
  const { sid, utmSource } = useQueryParams();
  const [identityFactors, setIdentityFactors] = useState([]);
  const [deviceFactors, setDeviceFactors] = useState([]);
  const [identityError, setIdentityError] = useState(false);
  const [deviceError, setDeviceError] = useState(false);

  const setIdentityFactorsArray = (option) => {
    // Handle the selection of identity columns here
    if (identityFactors.includes(option)) {
      // If the option is already selected, remove it
      setIdentityFactors(identityFactors.filter((item) => item !== option));
    } else {
      // If the option is not selected, add it
      setIdentityFactors([...identityFactors, option]);
    }
  };

  const setDeviceFactorsArray = (option) => {
    // Handle the selection of identity columns here
    if (deviceFactors.includes(option)) {
      // If the option is already selected, remove it
      setDeviceFactors(deviceFactors.filter((item) => item !== option));
    } else {
      // If the option is not selected, add it
      setDeviceFactors([...deviceFactors, option]);
    }
  };

  const { setAssessment } = useAssessmentContext();
  const { sessionId } = useGlobalState();

  const sendHeightToParent = () => {
    window.parent.postMessage(
      {
        type: "SET_HEIGHT",
      },
      "*"
    ); // Replace this URL with the parent page URL for security
  };

  const handleClick = () => {
    if (identityFactors.length === 0) {
      setIdentityError(true);
    }
    if (deviceFactors.length === 0) {
      setDeviceError(true);
    }
    if (identityFactors.length !== 0 && deviceFactors.length !== 0) {
      setIdentityError(false);
      setDeviceError(false);
      handleAssess();
    }
  };

  const handleAssess = async () => {
    let sessId = sessionId;
    let source = "direct";
    if (sid) {
      sessId = sid;
    }
    if (utmSource) {
      source = utmSource;
    }

    let postData = {
      sid: sessId,
      source: source,
      identityFactors: identityFactors,
      deviceFactors: deviceFactors,
      biFactor: false,
    };
    setAssessment((prevState) => ({
      ...prevState,
      loading: true,
    }));

    try {
      setTimeout(async () => {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/assess`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(postData),
          }
        );

        if (response.ok) {
          const data = await response.json();
          sendHeightToParent();
          setAssessment({
            loading: false,
            biFactor: false,
            identityFactors: identityFactors,
            identityScore: data.identityScore,
            deviceFactors: deviceFactors,
            deviceScore: data.deviceScore,
          });
        } else {
          console.error("POST request failed.");
        }
      }, 500); // // Delay of half a second
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <>
      <Box
        padding="24px"
        sx={{
          maxHeight: 446,
          overflowY: "scroll",
          backgroundColor: "#FBFBFB",
        }}
      >
        <Box>
          <Typography
            variant="body2"
            fontSize={18}
            fontWeight={700}
            lineHeight="140%"
            color="#333"
          >
            Your security environment
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <IdentityFactors
            setIdentityFactorsArray={setIdentityFactorsArray}
            error={identityError}
          />
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <DeviceFactors
            setDeviceFactorsArray={setDeviceFactorsArray}
            error={deviceError}
          />
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          padding: "16px 24px",
          backgroundColor: "#FBFBFB",
        }}
      >
        <Button
          sx={{ width: "100%" }}
          variant="contained"
          onClick={handleClick}
        >
          <Typography fontSize={16} fontWeight={700}>
            Assess
          </Typography>
        </Button>
      </Box>
    </>
  );
}

export default FactorsCard;
