import { List, ListItem } from "@mui/material";

function BulletList({ listItems }) {
  return (
    <List sx={{ listStyleType: "disc", pl: 2 }}>
      {listItems.map((item, index) => (
        <ListItem sx={{ display: "list-item", padding: 0, marginBottom: "4px" }} key={index}>
          {item}
        </ListItem>
      ))}
    </List>
  );
}

export default BulletList;
