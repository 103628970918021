import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; // Import the Link component
import GetDemoModal from "./get-demo-modal";

const NavBar = () => {
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setHasScrolled(true);
      } else {
        setHasScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [pad, setPad] = useState("96px");

  useEffect(() => {
    function adjustMargin() {
      const screenWidth = window.innerWidth;
      if (screenWidth < 600) {
        setPad("12px");
      } else if (screenWidth < 900) {
        setPad("36px");
      } else {
        setPad("96px");
      }
    }
    adjustMargin();
    window.addEventListener("resize", adjustMargin);
    return () => {
      window.removeEventListener("resize", adjustMargin);
    };
  }, []);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: "#F6F7FC", // Make the Navbar transparent
        boxShadow: hasScrolled ? "" : "none", // Remove the shadow
        padding: `0px ${pad}`,
      }}
    >
      <Toolbar
        padding="0px"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "44px",
          }}
        >
          <Link
            to="https://www.workbench.beyondidentity.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "12px",
              }}
            >
              <img src="bilogo.svg" alt="Beyond Identity" />
              <Box
                padding="4px 8px"
                sx={{
                  borderRadius: "4px",
                  background: "var(--primary-border, #C1D0F0)",
                }}
              >
                <Typography
                  variant="body2"
                  fontSize={12}
                  fontWeight={800}
                  color={"black"}
                >
                  TOOLS
                </Typography>
              </Box>
            </Box>
          </Link>
          <Box
            sx={{
              height: "100%",
              width: "100%",
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "12px",
                cursor: "pointer",
              }}
            >
              <Typography
                variant="body2"
                fontSize={20}
                fontWeight={500}
                color={"black"}
                sx={{
                  display: "flex",
                  gap: "4px",
                }}
              >
                Tools
                <ArrowDropDownIcon />
              </Typography>
            </Box>
            <Menu
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleMouseLeave}
              MenuListProps={{
                onMouseLeave: handleMouseLeave,
              }}
              sx={{
                marginTop: "12px",
              }}
            >
              <MenuItem
                onClick={() =>
                  window.open(
                    "https://www.workbench.beyondidentity.com/phishing-101",
                    "_blank"
                  )
                }
              >
                Phishing 101
              </MenuItem>
              <MenuItem
                onClick={() =>
                  window.open(
                    "https://www.workbench.beyondidentity.com/phishing-kill-chain-analysis",
                    "_blank"
                  )
                }
              >
                Phishing Kill Chain Analysis
              </MenuItem>
              <MenuItem
                onClick={() =>
                  window.open(
                    "https://www.beyondidentity.com/resources/okta-defense-kit",
                    "_blank"
                  )
                }
              >
                Okta Defense Kit
              </MenuItem>
              <MenuItem
                onClick={() =>
                  window.open(
                    "https://darkguard.beyondidentity.com/?utm_source=zta&utm_medium=sitereferral",
                    "_blank"
                  )
                }
              >
                DarkGuard BETA
              </MenuItem>
            </Menu>
          </Box>
        </Box>

        <Box>
          <Button
            variant="contained"
            sx={{
              padding: "8px 16px",
            }}
            onClick={handleOpen}
          >
            <Typography fontSize={16} fontWeight={600}>
              Get a demo
            </Typography>
          </Button>
          <GetDemoModal open={open} handleClose={handleClose} />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
