import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { Box } from "@mui/material";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { loginFactorsMap } from "../../../factors";
import { useAssessmentContext } from "../assessment.context";
import { scoreCardContentMap } from "../results/score-card";
import BulletList from "./bullet-list";

const IdentityEnvironmentCard = () => {
  const { assessment } = useAssessmentContext();

  const [margin, setMargin] = useState("39px"); // Default margin

  useEffect(() => {
    // Define the function to adjust the margin based on screen width
    function adjustMargin() {
      const screenWidth = window.innerWidth;
      if (screenWidth < 900) {
        setMargin("0px"); // Adjusted margin for smaller screens
      } else {
        setMargin("39px"); // Default margin
      }
    }

    // Call the adjustMargin function initially
    adjustMargin();

    // Add an event listener to adjust margin when the window is resized
    window.addEventListener("resize", adjustMargin);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", adjustMargin);
    };
  }, []);

  let factorListItems = getIdentityListItems(assessment.identityFactors);
  let { whyListItems, improveListItems } = getAnalysisListItems(
    assessment.identityScore
  );

  let biListItems1 = [
    <Typography fontSize={16} fontWeight={400} lineHeight="160%">
      Beyond Identity will provide your organization optimal identity assurance
      with phish-resistant MFA and continuous authentication using real-time
      risk signals. We provide effortless access for employees, contractors, and
      developers and prevent identity-based attacks on your enterprise
      resources.
    </Typography>,
  ];

  let biListItems2 = [
    <Typography fontSize={16} fontWeight={400} lineHeight="160%">
      <Link
        href="https://www.beyondidentity.com/solutions/phishing-resistant-mfa"
        target="_blank"
        rel="noopener noreferrer"
      >
        Phishing-resistant passkey MFA
      </Link>{" "}
      using device-bound cryptographic keys, delivering AAL3 level security
    </Typography>,
    <Typography fontSize={16} fontWeight={400} lineHeight="160%">
      <Link
        href="https://www.beyondidentity.com/resource/zero-trust-and-continuous-authentication-a-partnership-for-network-security"
        target="_blank"
        rel="noopener noreferrer"
      >
        Continuous authentication
      </Link>{" "}
      and risk analysis, ensuring compliant user behavior beyond the first login
    </Typography>,
    <Typography fontSize={16} fontWeight={400} lineHeight="160%">
      Elimination of{" "}
      <Link
        href="https://www.beyondidentity.com/resource/phishable-vs-unphishable-mfa-factors"
        target="_blank"
        rel="noopener noreferrer"
      >
        phishable factors
      </Link>{" "}
      for your entire organization
    </Typography>,
    <Typography fontSize={16} fontWeight={400} lineHeight="160%">
      A seamless{" "}
      <Link
        href="https://www.beyondidentity.com/solutions/passwordless-mfa"
        target="_blank"
        rel="noopener noreferrer"
      >
        passwordless authentication
      </Link>{" "}
      for your end users, reducing password-related help desk calls{" "}
    </Typography>,
  ];

  return (
    <>
      <Box display="flex" alignItems="center" gap="8px" marginBottom="24px">
        <PermIdentityIcon />
        <Typography
          variant="body2"
          fontSize={18}
          fontWeight={700}
          lineHeight="140%"
        >
          Your identity environment
        </Typography>
      </Box>

      <Grid container spacing="78px" sx={{ paddingBottom: "0px" }}>
        <Grid item xs={12} sm={12} md={6}>
          <Box>
            <Box
              display="flex"
              alignItems="center"
              gap="8px"
              marginBottom="24px"
              width="100%"
              minHeight="40px"
              padding="4px 8px"
              sx={{
                borderRadius: "8px",
                backgroundColor: `${
                  scoreCardContentMap.get(assessment.identityScore).bgColor
                }`,
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body2"
                fontSize={16}
                fontWeight={700}
                lineHeight="150%"
                align="center"
              >
                Current identity rating:{" "}
                {scoreCardContentMap.get(assessment.identityScore).heading}
              </Typography>
            </Box>
            <Typography
              variant="body2"
              fontSize={16}
              fontWeight={700}
              lineHeight="160%"
            >
              Why you got the rating
            </Typography>
            <BulletList listItems={whyListItems} />
            {assessment.identityScore < 4 && (
              <>
                <Typography
                  variant="body2"
                  fontSize={16}
                  fontWeight={700}
                  lineHeight="160%"
                >
                  How to improve your rating
                </Typography>
                <BulletList listItems={improveListItems} />
              </>
            )}
            <Typography
              variant="body2"
              fontSize={16}
              fontWeight={700}
              lineHeight="160%"
            >
              Analysis of authentication factors
            </Typography>
            <BulletList listItems={factorListItems} />
          </Box>
          {margin === "0px" && <Divider />}
        </Grid>

        {margin === "39px" && (
          <Divider orientation="vertical" flexItem sx={{ mr: "-1px" }} />
        )}
        <Grid item xs={12} sm={12} md={6}>
          <Box>
            <Box
              display="flex"
              alignItems="center"
              gap="8px"
              marginBottom="24px"
              minHeight="40px"
              padding="4px 8px"
              sx={{
                borderRadius: "8px",
                backgroundColor: `${scoreCardContentMap.get(4).bgColor}`,
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body2"
                fontSize={16}
                fontWeight={700}
                lineHeight="150%"
                align="center"
              >
                Identity rating with Beyond Identity:{" "}
                {scoreCardContentMap.get(4).heading}
              </Typography>
            </Box>
            <Typography
              variant="body2"
              fontSize={16}
              fontWeight={700}
              lineHeight="160%"
            >
              Why Beyond Identity is Optimal
            </Typography>
            <BulletList listItems={biListItems1} />
            <Typography
              variant="body2"
              fontSize={16}
              fontWeight={700}
              lineHeight="160%"
            >
              Beyond Identity's zero trust solution
            </Typography>
            <BulletList listItems={biListItems2} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

function getAnalysisListItems(identityScore) {
  let whyListItems = [];
  switch (identityScore) {
    case 1:
      whyListItems.push(
        <Typography fontSize={16} fontWeight={400} lineHeight="160%">
          Your rating is traditional because your authentication protocol is
          reliant on a single, phishable factor.
        </Typography>
      );
      break;
    case 2:
      whyListItems.push(
        <Typography fontSize={16} fontWeight={400} lineHeight="160%">
          Your rating is initial because your authentication protocol utilizes
          multiple factors, but at least one of them is phishable.
        </Typography>
      );
      break;
    case 3:
      whyListItems.push(
        <Typography fontSize={16} fontWeight={400} lineHeight="160%">
          Your rating is advanced because your authentication protocol only
          utilizes phish-resistant factors, but does not continuously
          authenticate users to prevent identity-based attacks mid-session.
        </Typography>
      );
      break;
    case 4:
      whyListItems.push(
        <Typography fontSize={16} fontWeight={400} lineHeight="160%">
          Your rating is optimal because your authentication protocol only
          utilizes phish-resistant factors, and you’re continuously
          authenticating your users for any risk that may appear.
        </Typography>
      );
      break;
    default:
    // do nothing
  }

  let improveListItems = [];
  if (identityScore < 2) {
    improveListItems.push(
      <Typography fontSize={16} fontWeight={400} lineHeight="160%">
        <Typography
          variant="body2"
          style={{
            backgroundColor: scoreCardContentMap.get(2).bgColor,
            display: "inline",
            padding: "4px 8px",
            borderRadius: "8px",
          }}
          fontSize={16}
          fontWeight={700}
        >
          {scoreCardContentMap.get(2).heading}
        </Typography>{" "}
        : to get to an initial rating, additional factors (MFA) should be added
        to your authentication protocol. If the factors are not phish-resistant,
        then you will remain vulnerable to phishing attacks.
      </Typography>
    );
  }
  if (identityScore < 3) {
    improveListItems.push(
      <Typography fontSize={16} fontWeight={400} lineHeight="160%">
        <Typography
          variant="body2"
          style={{
            backgroundColor: scoreCardContentMap.get(3).bgColor,
            display: "inline",
            padding: "4px 8px",
            borderRadius: "8px",
          }}
          fontSize={16}
          fontWeight={700}
        >
          {scoreCardContentMap.get(3).heading}
        </Typography>{" "}
        : to get to an advanced rating, you should ensure your authentication
        protocol only utilizes phish-resistant factors. However, if you do not
        also implement continuous user authentication, then you will remain
        vulnerable to identity-based attacks that might occur post-login, during
        the user's session.
      </Typography>
    );
  }
  if (identityScore < 4) {
    improveListItems.push(
      <Typography fontSize={16} fontWeight={400} lineHeight="160%">
        <Typography
          variant="body2"
          style={{
            backgroundColor: scoreCardContentMap.get(4).bgColor,
            display: "inline",
            padding: "4px 8px",
            borderRadius: "8px",
          }}
          fontSize={16}
          fontWeight={700}
        >
          {scoreCardContentMap.get(4).heading}
        </Typography>{" "}
        : to get to an optimal rating, you should ensure your authentication
        protocol only utilizes phish-resistant factors and authenticates users
        continuously with real-time risk signals. This fully realizes the
        principle “never trust, always verify” and ensures comprehensive
        protection against identity-based attacks.
      </Typography>
    );
  }

  return { whyListItems, improveListItems };
}

function getIdentityListItems(identityFactors) {
  let labelItems = [];

  for (const [key, data] of loginFactorsMap) {
    if (identityFactors.includes(key)) {
      if (data.envText !== undefined && data.envText !== "") {
        labelItems.push(
          <Typography
            variant="body2"
            fontSize={16}
            fontWeight={400}
            lineHeight="160%"
          >
            <strong>{data.labelLink}</strong>: {data.envText}
          </Typography>
        );
      }
    }
  }

  return labelItems;
}

export default IdentityEnvironmentCard;
