import { Link } from "react-router-dom"; // Import the Link component

const loginFactorsMap = new Map([
  [
    "password",
    {
      label: "Password",
      labelLink: (
        <Link
          to="https://www.workbench.beyondidentity.com/phishable/password"
          target="_blank"
          rel="noopener noreferrer"
        >
          Password
        </Link>
      ),
      tooltip:
        "Select this if your organization uses passwords as an authentication factor.",
      envText:
        "Passwords are vulnerable to theft, and users may choose weak, easily guessable passwords. They can also be exposed in data breaches, leading to the compromise of multiple accounts. There's also a significant cost for password resets.",
    },
  ],
  [
    "sms",
    {
      label: "SMS",
      labelLink: (
        <Link
          to="https://www.workbench.beyondidentity.com/phishable/sms"
          target="_blank"
          rel="noopener noreferrer"
        >
          SMS
        </Link>
      ),
      tooltip:
        "Select this if your organization uses SMS one-time passcodes as an authentication factor.",
      envText:
        "SMS codes can be intercepted through SIM swapping or phishing attacks, making them susceptible to unauthorized access. Reliance on SMS assumes that the user's mobile device is secure and the network is trustworthy, which may not be the case.",
    },
  ],
  [
    "email",
    {
      label: "Email Magic Link / OTP",
      tooltip:
        "Select this if your organization uses email magic links / one-time passcodes as an authentication factor.",
      envText:
        "Email magic links / OTPs are vulnerable to phishing attacks that trick users into clicking malicious links. They may not guarantee a user's true identity since email accounts can be compromised.",
    },
  ],
  [
    "authenticator-app",
    {
      label: "TOTP Authenticator App",
      labelLink: (
        <Link
          to="https://www.workbench.beyondidentity.com/phishable/totp"
          target="_blank"
          rel="noopener noreferrer"
        >
          TOTP Authenticator App
        </Link>
      ),
      tooltip:
        "Select this if your organization uses an authenticator app for time-based one time passcodes as an authentication factor. This includes apps such as Google Authenticator, Microsoft Authenticator, etc.",
      envText:
        "Time-based, one-time passcodes (TOTP) are static and can be used by attackers if they are intercepted during transmission. Users may not adequately protect their TOTP seeds or devices, making them vulnerable to theft. TOTPs also require a dedicated app for generating codes, which can be seen as an inconvenience.",
    },
  ],
  [
    "push",
    {
      label: "Push",
      labelLink: (
        <Link
          to="https://www.workbench.beyondidentity.com/phishable/push"
          target="_blank"
          rel="noopener noreferrer"
        >
          Push
        </Link>
      ),
      tooltip:
        "Select this if your organization uses push as an authentication factor.",
      envText:
        "Push notifications depend on a secure device and communication channel security, which may not always be the case. Users may approve requests without proper verification, particularly when under duress (push fatigue phishing) or on compromised devices. It also requires a dedicated app, which can be seen as inconvenient.",
    },
  ],
  [
    "biometric",
    {
      label: "Biometric",
      labelLink: (
        <Link
          to="https://www.workbench.beyondidentity.com/phish-resistant/biometrics"
          target="_blank"
          rel="noopener noreferrer"
        >
          Biometrics
        </Link>
      ),
      tooltip:
        "Select this if your organization uses biometrics as an authentication factor.",
      envText:
        "Biometrics, like fingerprints and facial recognition, offer strong and unique authentication. Unlike passwords, biometric data is immutable and but does pose privacy risks if compromised.",
    },
  ],
  [
    "hardware-key",
    {
      label: "Hardware Security Key",
      labelLink: (
        <Link
          to="https://www.workbench.beyondidentity.com/phish-resistant/hardware-security-key"
          target="_blank"
          rel="noopener noreferrer"
        >
          Hardware Security Key
        </Link>
      ),
      tooltip:
        "Select this if your organization uses external security keys, such as yubikeys, as an authentication factor.",
      envText:
        "Hardware security keys provide strong, phishing-resistant security. The protection of these keys is paramount to prevent loss or theft.",
    },
  ],
  [
    "passkey",
    {
      label: "Passkey",
      labelLink: (
        <Link
          to="https://www.workbench.beyondidentity.com/phish-resistant/fido-passkey"
          target="_blank"
          rel="noopener noreferrer"
        >
          FIDO Passkey
        </Link>
      ),
      tooltip:
        "Select this if your organization uses FIDO passkeys as an authentication factor.",
      envText:
        "FIDO Passkeys provide strong, phishing-resistant authentication. Proper key management is essential.",
    },
  ],
  [
    "cont-auth",
    {
      label: "Continuous Authentication",
      labelLink: (
        <Link
          to="https://www.workbench.beyondidentity.com/advanced-topics/continuous-authentication"
          target="_blank"
          rel="noopener noreferrer"
        >
          Continuous Authentication
        </Link>
      ),
      tooltip:
        "Select this if your organization's security protocol involves continuous authentication past the initial login.",
      envText:
        "Continuous authentication is a security approach that constantly verifies a user's identity throughout their session, providing an added layer of protection against unauthorized access or suspicious activity. Users should be continuously validated for risk analysis.",
    },
  ],
]);

const deviceFactorsMap = new Map([
  [
    "byod",
    {
      label: "Bring Your Own Device (BYOD)",
      tooltip: "Select this if your organization has BYOD/contractor devices.",
      envText:
        "BYOD presents security challenges due to the diversity of device types and security postures, the risk of data leakage, less cautious user behavior, and the increased potential for lost or stolen devices. Implementing MDM and EDR controls can be difficult for BYOD/contractor devices as users may resist control and compatibility issues arise.",
    },
  ],
  [
    "vpn",
    {
      label: "Virtual Private Network (VPN)",
      tooltip: "Select this if your organization uses VPNs on your devices.",
      envText:
        "VPNs establish secure, encrypted tunnels for remote access, safeguarding data in transit. Yet, vulnerabilities in software and configuration, along with evolving threats, remain concerns.",
    },
  ],
  [
    "vdi",
    {
      label: "Virtual Desktops",
      tooltip: "Select this if your organization uses virtual desktops.",
      envText:
        "Virtual desktops provide isolated environments for testing and security research, reducing risks to production systems. It can be vulnerable to virtualization software vulnerabilities and misconfigurations.",
    },
  ],
  [
    "edr",
    {
      label: "Endpoint Detection and Response (EDR)",
      tooltip: `Select this if your organization uses Endpoint Detection and Response (EDR) to manage devices.`,
      envText:
        "EDR solutions offer real-time monitoring, threat detection, and response on device endpoints, but they can be vulnerable to advanced threat evasion techniques and require regular updates and configuration for effectiveness.",
    },
  ],
  [
    "ztna",
    {
      label: "Zero Trust Network Access (ZTNA)",
      tooltip: `Select this if your organization uses Zero Trust Network Access (ZTNA) to manage devices.`,
      envText:
        "ZTNA enforces strict access controls, only granting access to specific resources after strong authentication, authorization, and identifying device risk signals. The configuration and policy must be kept up to date to block compromised devices and accounts.",
    },
  ],
  [
    "asset-inventory",
    {
      label: "Automated Asset Inventory",
      tooltip:
        "Select this if your organization keeps track of your physical and virtual assets through an automated asset investory system.",
      envText:
        "Automated asset inventory systems track and manage devices on the network. They help identify unauthorized devices. However, it is susceptible to risk for devices that evade detection or aren't regularly updated in the inventory.",
    },
  ],
  [
    "mdm",
    {
      label: "MDM Managed Devices and Policy",
      tooltip:
        "Select this if your organization uses an MDM to manage devices.",
      envText:
        "Mobile Device Management (MDM) enforces security policies on mobile devices, ensuring secure configurations. However, it is vulnerable to device misconfigurations bypassing MDM controls or device compromise.",
    },
  ],

  [
    "secure-browser",
    {
      label: "Secure Browser",
      tooltip: "Select this if your organization uses a secure browser.",
      envText:
        "Secure browsers isolate web content to enhance web security but are vulnerable to browser vulnerabilities and, in some cases, advanced web threats.",
    },
  ],
  [
    "cont-posture",
    {
      label: "Continuous Device Posture Check",
      tooltip:
        "Select this if your organization's security protocol involves continuous device posture checks",
      envText:
        "Continuous device posture checks ensure that the security and compliance status of devices accessing the network is regularly assessed to identify and address potential vulnerabilities. Resource access should depend on real-time device risk analytics.",
    },
  ],
]);

export { deviceFactorsMap, loginFactorsMap };
