import DevicesIcon from "@mui/icons-material/Devices";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Unstable_Grid2";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import useQueryParams from "utils/query-parser";
import { useGlobalState } from "../../../global-context";
import { useAssessmentContext } from "../assessment.context";
import ScoreCard from "./score-card";

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

const AssessmentChart = () => {
  const [v, setV] = useState(false); // Default margin
  const [margin, setMargin] = useState("120px"); // Default margin

  useEffect(() => {
    // Define the function to adjust the margin based on screen width
    function adjustMargin() {
      const screenWidth = window.innerWidth;
      if (screenWidth < 1100) {
        setV(true); // Adjusted margin for smaller screens
      } else {
        setV(false); // Default margin
      }
      if (screenWidth < 1200) {
        setMargin("8px"); // Adjusted margin for smaller screens
      } else {
        setMargin("120px"); // Default margin
      }
    }

    // Call the adjustMargin function initially
    adjustMargin();

    // Add an event listener to adjust margin when the window is resized
    window.addEventListener("resize", adjustMargin);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", adjustMargin);
    };
  }, []);

  const { assessment, setAssessment } = useAssessmentContext();
  const { sessionId } = useGlobalState();
  const { sid, utmSource } = useQueryParams();

  const handleChange = (event) => {
    handleAssess({ biFactor: event.target.checked });
  };

  const handleAssess = async ({ biFactor }) => {
    let identityFactors = assessment.identityFactors;
    let deviceFactors = assessment.deviceFactors;
    let sessId = sessionId;
    let source = "direct";
    if (sid) {
      sessId = sid;
    }
    if (utmSource) {
      source = utmSource;
    }

    let postData = {
      sid: sessId,
      source: source,
      identityFactors: identityFactors,
      deviceFactors: deviceFactors,
      biFactor: biFactor,
    };

    setAssessment((prevState) => ({
      ...prevState,
      loading: true,
    }));

    try {
      // Add a delay of 2 seconds (2000 milliseconds)
      setTimeout(async () => {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/assess`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(postData),
          }
        );

        if (response.ok) {
          const data = await response.json();

          setAssessment({
            loading: false,
            biFactor: biFactor,
            identityFactors: identityFactors,
            identityScore: data.identityScore,
            deviceFactors: deviceFactors,
            deviceScore: data.deviceScore,
          });
        } else {
          console.error("POST request failed.");
        }
      }, 500); // Delay of half a second
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Box
      sx={{
        padding: "24px 24px 16px 24px",
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        gap="9px"
        marginBottom="24px"
        sx={{
          display: "flex",
          justifyContent: v ? "" : "space-between",
          flexDirection: v ? "column" : "",
          alignItems: v ? "" : "center",
          width: "100%",
        }}
      >
        <Typography
          variant="body2"
          fontSize={18}
          fontWeight={700}
          lineHeight="140%"
        >
          Your zero trust assessment{" "}
          {assessment.biFactor && "(with Beyond Identity)"}
        </Typography>
      </Box>
      {assessment.loading ? (
        <>
          <Grid xs={12} container justifyContent="center">
            <Box margin="170px 0px 130px 0px">
              <CircularProgress />
            </Box>
          </Grid>
        </>
      ) : (
        <Box
          marginBottom="24px"
          sx={{ flex: 1, display: "flex", alignItems: "center", width: "100%" }}
        >
          <Grid container spacing="24px" sx={{ width: "100%" }}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <ScoreCard
                type="identity"
                icon={<PermIdentityIcon />}
                score={assessment.biFactor ? 4 : assessment.identityScore}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <ScoreCard
                type="device"
                icon={<DevicesIcon />}
                score={assessment.biFactor ? 4 : assessment.deviceScore}
              />
            </Grid>
          </Grid>
        </Box>
      )}
      <Box
        alignItems="center"
        gap="16px"
        sx={{
          marginTop: "auto",
          minHeight: "37px",
        }}
      >
        {assessment.identityScore > 0 && (
          <Box
            display="inline-block"
            sx={{
              borderRadius: "8px",
              padding: "8px 16px ",
              background: "#F6F7FC",
            }}
          >
            <FormControlLabel
              control={
                <Android12Switch
                  checked={assessment.biFactor}
                  onChange={handleChange}
                  name="checked"
                  color="primary" // You can change the color to 'secondary' or other options
                />
              }
              label={
                <Typography
                  fontSize={16}
                  fontWeight={400}
                  lineHeight="160%"
                  sx={{ paddingTop: "4px" }}
                >
                  See your rating with Beyond Identity and scroll down to learn
                  more
                </Typography>
              }
              sx={{
                marginRight: 0,
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AssessmentChart;
