import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import { useAssessmentContext } from "../assessment.context";

const scoreCardContentMap = new Map([
  [
    4,
    {
      bgColor: "#D0EEDE",
      heading: "Optimal",
      identity:
        "Advanced identity management with continuous, phishing-resistant MFA and automated response mechanisms for both users and devices.",
      device:
        "Advanced device management with real-time monitoring, continuous validation, and automated enforcement for a diverse set of devices.",
      identityBi:
        "Beyond Identity leverages phishing-resistant MFA and continuously validates a user's identity throughout a session.",
      deviceBi:
        "Beyond Identity continuously monitors, assesses, and enforces the security posture of all devices connecting to your network.",
    },
  ],
  [
    3,
    {
      bgColor: "#FFF3CD",
      heading: "Advanced",
      identity:
        "Extensive identity management using phishing-resistant MFA and integration with automation for users and devices.",
      device:
        "Comprehensive device management with full visibility of devices, including some automated provisioning, monitoring, and control.",
    },
  ],
  [
    2,
    {
      bgColor: "#FFEBD4",
      heading: "Initial",
      identity:
        "Initial identity management using MFA with phishable factors and includes some device awareness, but with limited integration.",
      device:
        "Initial device management with limited visibility and limited enforcement of device posture.",
    },
  ],
  [
    1,
    {
      bgColor: "#FFECEC",
      heading: "Traditional",
      identity:
        "Basic identity management using a phishable factor, with limited identity risk assessments.",
      device:
        "Basic device management, with limited device compliance for resource access. Little to no visibility or control.",
    },
  ],
  [
    0,
    {
      identity: "",
      device: "",
    },
  ],
]);

const ScoreCard = ({ type, icon, score }) => {
  const { assessment } = useAssessmentContext();

  const scoreOptions = Array.from(scoreCardContentMap).map(([id, data]) => {
    let bgColor = "#f0eded";
    let fontColor = "#706e6e";
    if (score === id) {
      fontColor = "black";
      bgColor = data.bgColor;
    }
    if (id === 0 || id === 5) {
      return <Box />;
    }

    return (
      <Box key={`${type}-score-${data.heading}`} marginBottom="8px">
        <Paper
          elevation={0}
          sx={{
            display: "flex",
            backgroundColor: bgColor,
            height: "37px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body2"
            align="center"
            fontSize={14}
            fontWeight={700}
            lineHeight="150%"
            color={fontColor}
          >
            {data.heading}
          </Typography>
        </Paper>
      </Box>
    );
  });

  return (
    <Paper
      elevation={0}
      sx={{
        boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.10)",
        height: "100%",
        width: "100%",
      }}
    >
      <Box sx={{ padding: "16px" }}>
        <Box display="flex" alignItems="center" gap="9px">
          {icon}
          <Typography
            variant="body2"
            fontSize={12}
            fontWeight={800}
            lineHeight="140%"
            letterSpacing="0.24"
            textTransform="uppercase"
          >
            {type} Rating
          </Typography>
        </Box>
        <Box
          border="1px solid rgba(0, 0, 0, 0.05)"
          borderRadius="4px"
          background="var(--neutral-grey-10-white-ffffff, #FFF)"
          padding="8px"
          height="192px"
          width="100%"
          marginTop="16px"
        >
          {scoreOptions}
        </Box>
        <Box marginTop="16px">
          <Typography fontSize={14} fontWeight={400} lineHeight="160%">
            {assessment.biFactor
              ? scoreCardContentMap.get(score)[`${type}Bi`]
              : scoreCardContentMap.get(score)[type]}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export { scoreCardContentMap };

export default ScoreCard;
