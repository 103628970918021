import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";

function IconTooltip({ name, tooltip }) {
  return (
    <Tooltip
      key={name}
      arrow
      placement="right"
      title={<Typography fontSize={12}>{tooltip}</Typography>}
    >
      <InfoOutlined sx={{ fontSize: 20 }} />
    </Tooltip>
  );
}

export default IconTooltip;
