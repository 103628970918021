import { createTheme } from "@mui/material/styles";

const beyondBlue2 = "#3727D6";

const theme = createTheme({
  palette: {
    background: {
      default: "#ffffff", // Background color for the card
      paper: "#ffffff", // Card container background
    },
    text: {
      primary: "#333", // Primary text color inside the card
    },
    divider: "rgba(0, 0, 0, 0.12)", // Card border color
    primary: {
      main: beyondBlue2, // Primary color for elements like buttons
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#ffffff", // Secondary color for elements like accents
    },
  },
  typography: {
    fontFamily: "Inter, sans-serif",
    body2: {
      fontFamily: ["Manrope", "sans-serif"].join(","),
      fontSize: 12,
    },
    button: {
      textTransform: "none", // Set text transformation to "none" for button text
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "#ffffff",
          backgroundImage: "#F6F7FC",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "20px", // Adjust the value as needed
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
  },
});
export default theme;
